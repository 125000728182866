import service from '@/utils/request';
export var editRole = function editRole(data) {
  return service({
    url: "/permission/roles",
    method: 'post',
    data: data
  });
};
export var deleteRole = function deleteRole(data) {
  return service({
    url: "/permission/roles",
    method: 'delete',
    data: data
  });
};
export var getRoleList = function getRoleList(params) {
  return service({
    url: "/permission/roles",
    method: 'get',
    params: params
  });
};
export var editPermission = function editPermission(data) {
  return service({
    url: "/permission/permissions",
    method: 'post',
    data: data
  });
};
export var deletePermission = function deletePermission(data) {
  return service({
    url: "/permission/permissions",
    method: 'delete',
    data: data
  });
};
export var getPermissionList = function getPermissionList(params) {
  return service({
    url: "/permission/permissions",
    method: 'get',
    params: params
  });
};
export var setRole = function setRole(data) {
  return service({
    url: "/permission/set_role",
    method: 'post',
    data: data
  });
};
export var setRolePermission = function setRolePermission(data) {
  return service({
    url: "/permission/set_role_permission",
    method: 'post',
    data: data
  });
};