import service from '@/utils/request'; // @Tags User
// @Summary 创建User
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.User true "创建User"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /users/createUser [post]

export var createUser = function createUser(data) {
  return service({
    url: "/users/createUser",
    method: 'post',
    data: data
  });
}; // @Tags User
// @Summary 删除User
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.User true "删除User"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /users/deleteUser [delete]

export var deleteUser = function deleteUser(data) {
  return service({
    url: "/users/deleteUser",
    method: 'delete',
    data: data
  });
}; // @Tags User
// @Summary 删除User
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除User"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /users/deleteUser [delete]

export var deleteUserByIds = function deleteUserByIds(data) {
  return service({
    url: "/users/deleteUserByIds",
    method: 'delete',
    data: data
  });
}; // @Tags User
// @Summary 更新User
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.User true "更新User"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /users/updateUser [put]

export var updateUser = function updateUser(data) {
  return service({
    url: "/users/updateUser",
    method: 'put',
    data: data
  });
}; // @Tags User
// @Summary 用id查询User
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.User true "用id查询User"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /users/findUser [get]

export var findUser = function findUser(params) {
  return service({
    url: "/users/findUser",
    method: 'get',
    params: params
  });
}; // @Tags User
// @Summary 分页获取User列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取User列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /users/getUserList [get]

export var getUserList = function getUserList(params) {
  return service({
    url: "/users/getUserList",
    method: 'get',
    params: params
  });
}; // @Tags Users
// @Summary 管理员设置用户订购记录
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Users列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /users/buyserve [post]

export var buyServer = function buyServer(data) {
  return service({
    url: "/users/buyserve",
    method: 'post',
    data: data
  });
};
export var getUserBalance = function getUserBalance(params) {
  return service({
    url: "/users/get_balance",
    method: 'get',
    params: params
  });
};
export var changeUserBalance = function changeUserBalance(data) {
  return service({
    url: "/users/change_balance",
    method: 'post',
    data: data
  });
};
export var getPopularizeUsers = function getPopularizeUsers(params) {
  return service({
    url: "/users/popularize_user",
    method: 'get',
    params: params
  });
};
export var financeStatis = function financeStatis(data) {
  return service({
    url: "/users/finance_statis",
    method: 'post',
    data: data
  });
};
export var getUserLoginUrl = function getUserLoginUrl(params) {
  return service({
    url: "/users/getUserLoginUrl",
    method: 'get',
    params: params
  });
}; // @Tags Users
// @Summary 下载客户预充金列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "下载客户预充金列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /funds/download [get]

export var downloadPrepay = function downloadPrepay(params) {
  return service({
    url: "/funds/download",
    method: 'get',
    params: params
  });
};