import service from '@/utils/request'; // @Tags FundRecords
// @Summary 创建FundRecords
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.FundRecords true "创建FundRecords"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /fundRecords/createFundRecords [post]

export var createFundRecords = function createFundRecords(data) {
  return service({
    url: "/fundRecords/createFundRecords",
    method: 'post',
    data: data
  });
}; // @Tags FundRecords
// @Summary 删除FundRecords
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.FundRecords true "删除FundRecords"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /fundRecords/deleteFundRecords [delete]

export var deleteFundRecords = function deleteFundRecords(data) {
  return service({
    url: "/fundRecords/deleteFundRecords",
    method: 'delete',
    data: data
  });
}; // @Tags FundRecords
// @Summary 删除FundRecords
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除FundRecords"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /fundRecords/deleteFundRecords [delete]

export var deleteFundRecordsByIds = function deleteFundRecordsByIds(data) {
  return service({
    url: "/fundRecords/deleteFundRecordsByIds",
    method: 'delete',
    data: data
  });
}; // @Tags FundRecords
// @Summary 更新FundRecords
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.FundRecords true "更新FundRecords"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /fundRecords/updateFundRecords [put]

export var updateFundRecords = function updateFundRecords(data) {
  return service({
    url: "/fundRecords/updateFundRecords",
    method: 'put',
    data: data
  });
}; // @Tags FundRecords
// @Summary 用id查询FundRecords
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.FundRecords true "用id查询FundRecords"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /fundRecords/findFundRecords [get]

export var findFundRecords = function findFundRecords(params) {
  return service({
    url: "/fundRecords/findFundRecords",
    method: 'get',
    params: params
  });
}; // @Tags FundRecords
// @Summary 分页获取FundRecords列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取FundRecords列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /fundRecords/getFundRecordsList [get]

export var getFundRecordsList = function getFundRecordsList(params) {
  return service({
    url: "/fundRecords/getFundRecordsList",
    method: 'get',
    params: params
  });
};