var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.searchInfo.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "phone", $$v)
                      },
                      expression: "searchInfo.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关键词" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入关键词" },
                    model: {
                      value: _vm.searchInfo.username,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "username", $$v)
                      },
                      expression: "searchInfo.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clear: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.mode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "mode", $$v)
                        },
                        expression: "searchInfo.mode"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "自营", value: "1" }
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "代理", value: "2" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "ID", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { label: "手机号", prop: "phone", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户名", prop: "username", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "备注名", prop: "name", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "推广佣金", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s((scope.row.amount / 100).toFixed(2)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "模式", prop: "mode", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.mode === 1
                      ? _c("span", [_vm._v("自营模式")])
                      : _vm._e(),
                    scope.row.mode === 2
                      ? _c("span", [_vm._v("官方模式")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "用户注册来源", prop: "platform", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "最大应用数", prop: "maxClient", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "技术服务费", prop: "serviceRatio", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(scope.row.serviceRatio * 100) + "%")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "注册时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatDate")(scope.row.CreatedAt)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updateUsers(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.changeBalance(scope.row)
                          }
                        }
                      },
                      [_vm._v("余额")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "success" },
                        on: {
                          click: function($event) {
                            return _vm.accountDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleRoleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("角色")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.handleLoginUserAdmin(scope.row)
                          }
                        }
                      },
                      [_vm._v("登录")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑用户" : "创建用户"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "medium",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号", prop: "phone" } },
                            [
                              _c("el-input", {
                                style: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入手机号",
                                  readonly: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.formData.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "phone", $$v)
                                  },
                                  expression: "formData.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注名", prop: "name" } },
                            [
                              _c("el-input", {
                                style: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入备注名",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "模式", prop: "mode" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.formData.mode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "mode", $$v)
                                    },
                                    expression: "formData.mode"
                                  }
                                },
                                _vm._l(_vm.modeOptions, function(item, index) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.value,
                                        disabled: item.disabled
                                      }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务费率(%)",
                                prop: "serviceRatio",
                                required: ""
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  precision: 2,
                                  min: 0,
                                  max: 100,
                                  placeholder:
                                    "请输入0-100的数字，最多两位小数",
                                  label: "serviceRatio"
                                },
                                model: {
                                  value: _vm.formData.serviceRatio,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "serviceRatio", $$v)
                                  },
                                  expression: "formData.serviceRatio"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "最大应用数", prop: "maxClient" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, max: 999, label: "maxClient" },
                                model: {
                                  value: _vm.formData.maxClient,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "maxClient", $$v)
                                  },
                                  expression: "formData.maxClient"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关联代理商", prop: "partner_id" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.formData.ID && _vm.bindAgent,
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formData.partner_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "partner_id", $$v)
                                    },
                                    expression: "formData.partner_id"
                                  }
                                },
                                _vm._l(_vm.partnerList, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "品牌券价格", prop: "taxRatio" }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.formData.taxRatio,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "taxRatio", $$v)
                                    },
                                    expression: "formData.taxRatio"
                                  }
                                },
                                _vm._l(_vm.taxRatioOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.value,
                                        disabled: item.disabled
                                      }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                span: 24,
                                label: "伙伴服务费(%)",
                                prop: "partnerRatio",
                                width: "140"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: _vm.formData.ID && _vm.bindAgent,
                                  precision: 2,
                                  min: 0,
                                  max: 100,
                                  placeholder:
                                    "请输入0-100的数字，最多两位小数",
                                  label: "partnerRatio"
                                },
                                model: {
                                  value: _vm.formData.partnerRatio,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "partnerRatio", $$v)
                                  },
                                  expression: "formData.partnerRatio"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "账户资质", prop: "intelligence" }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.formData.intelligence,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "intelligence",
                                        $$v
                                      )
                                    },
                                    expression: "formData.intelligence"
                                  }
                                },
                                _vm._l(_vm.intelligenceOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: {
                                        label: item.value,
                                        disabled: item.disabled
                                      }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "服务订购", visible: _vm.dialogServerFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogServerFormVisible = $event
            }
          }
        },
        [
          _vm.userSubscriptions && _vm.userSubscriptions.length > 0
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.userSubscriptions,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "dark"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "服务标题",
                      prop: "ServiceName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "到期时间", prop: "ExpireTime" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.Expired == false
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(scope.row.ExpireTime))
                                  ])
                                : _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(_vm._s(scope.row.ExpireTime))
                                  ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      504173134
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "elForm",
              attrs: {
                model: _vm.serverForm,
                rules: _vm.Serverrules,
                size: "medium",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品服务  ", prop: "serviceId" } },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "100%" },
                      attrs: { placeholder: "请选择产品服务", clearable: "" },
                      model: {
                        value: _vm.serverForm.serviceId,
                        callback: function($$v) {
                          _vm.$set(_vm.serverForm, "serviceId", $$v)
                        },
                        expression: "serverForm.serviceId"
                      }
                    },
                    _vm._l(_vm.serviceIdOptions, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.title,
                          value: item.ID,
                          disabled: item.disabled
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "过期时间", prop: "expireTime" } },
                [
                  _c("el-date-picker", {
                    style: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: ""
                    },
                    model: {
                      value: _vm.serverForm.expireTime,
                      callback: function($$v) {
                        _vm.$set(_vm.serverForm, "expireTime", $$v)
                      },
                      expression: "serverForm.expireTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogServerFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleBuy } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "余额变动", visible: _vm.dialogBalanceFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogBalanceFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.userBalanceData,
                border: "",
                stripe: "",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "账户类型", prop: "name" }
              }),
              _c("el-table-column", {
                attrs: { label: "余额/元", prop: "amount" }
              })
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "elForm",
              attrs: {
                model: _vm.balanceForm,
                rules: _vm.balanceRules,
                size: "medium",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      style: { width: "100%" },
                      attrs: { placeholder: "请选择类型", clearable: "" },
                      model: {
                        value: _vm.balanceForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.balanceForm, "type", $$v)
                        },
                        expression: "balanceForm.type"
                      }
                    },
                    [
                      _vm._l(_vm.balanceOptions, function(item, index) {
                        return [
                          !item.disabled
                            ? _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "调整金额", prop: "change_amount" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "最多两位小数，增加(12.56)/减少(-12.56)"
                      },
                      model: {
                        value: _vm.balanceForm.change_amount,
                        callback: function($$v) {
                          _vm.$set(_vm.balanceForm, "change_amount", $$v)
                        },
                        expression: "balanceForm.change_amount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "调整原因", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "原因将展示在B端的明细字段" },
                    model: {
                      value: _vm.balanceForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.balanceForm, "remark", $$v)
                      },
                      expression: "balanceForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogBalanceFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBalanceChange }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "账户信息",
            "before-close": _vm.closeAccountDialog,
            visible: _vm.dialogAccountVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAccountVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            _vm._l(_vm.userBalanceData, function(balance, index) {
              return _c(
                "el-col",
                { key: index, attrs: { span: 8 } },
                [
                  _c("el-card", { attrs: { shadow: "always" } }, [
                    _vm._v(
                      " " +
                        _vm._s(balance.name) +
                        " " +
                        _vm._s(balance.amount) +
                        " 元 "
                    )
                  ])
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "search-term" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, model: _vm.fundQuery, size: "mini" }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.fundQuery.time_range,
                          callback: function($$v) {
                            _vm.$set(_vm.fundQuery, "time_range", $$v)
                          },
                          expression: "fundQuery.time_range"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账户类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型", clearable: "" },
                          model: {
                            value: _vm.fundQuery.type,
                            callback: function($$v) {
                              _vm.$set(_vm.fundQuery, "type", $$v)
                            },
                            expression: "fundQuery.type"
                          }
                        },
                        _vm._l(_vm.userBalanceData, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.type }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "流水类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型", clearable: "" },
                          model: {
                            value: _vm.fundQuery.rec_type,
                            callback: function($$v) {
                              _vm.$set(_vm.fundQuery, "rec_type", $$v)
                            },
                            expression: "fundQuery.rec_type"
                          }
                        },
                        _vm._l(_vm.balanceOptions, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.findFundRecords }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fundLoading,
                  expression: "fundLoading"
                }
              ],
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: {
                data: _vm.fundRecords,
                border: "",
                height: "300",
                stripe: "",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
              _c("el-table-column", {
                attrs: { label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.times))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "变动前金额", prop: "before_amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.before_amount) + " 元")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "变动金额", prop: "change_amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.change_amount) + " 元")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "变动后金额", prop: "after_amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.after_amount) + " 元")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark" }
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "center" },
            attrs: {
              "current-page": _vm.fundQuery.page,
              "page-size": _vm.fundQuery.pageSize,
              "page-sizes": [10, 30, 50, 100],
              total: _vm.fundTotal,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.fundQuery, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.fundQuery, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.fundQuery, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.fundQuery, "pageSize", $event)
              },
              "current-change": _vm.findFundRecords,
              "size-change": _vm.findFundRecords
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色设置",
            visible: _vm.dialogRoleVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRoleVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.roleLoading,
                  expression: "roleLoading"
                }
              ],
              ref: "roleForm",
              attrs: { model: _vm.roleForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "role_ids", "label-width": "10px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.roleForm.role_ids,
                        callback: function($$v) {
                          _vm.$set(_vm.roleForm, "role_ids", $$v)
                        },
                        expression: "roleForm.role_ids"
                      }
                    },
                    _vm._l(_vm.roleList, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.ID, attrs: { label: item.ID } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.roleSubmitting },
                  on: {
                    click: function($event) {
                      return _vm.handleRoleSave("roleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogRoleVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }